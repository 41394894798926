// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
import { ApiError } from 'services/AuthService';
import { UserResponse } from './AccountService';
import ApiBaseService from './ApiBaseService';

export type ShortUserType = Pick<
  UserResponse,
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'avatar'
  | 'department'
  | 'departmentId'
  | 'position'
  | 'active'
> & {
  id: number;
  employmentType: string;
};

export interface HolidayType {
  key: string;
  value: string;
}

export enum EnumEmploymentType {
  Vacation = 'vacation',
  Holiday = 'holiday',
  On_demand = 'on_demand',
  Sick = 'sick',
  Kid = 'kid',
  Occasional = 'occasional',
}

export enum HolidayStatuses {
  Accepted = 'accepted',
  Pending = 'pending',
  Declined = 'declined',
}

export interface Holiday {
  requestedBy: ShortUserType;
  holidayType: HolidayType['key'];
  days: number; // working days
  dateFrom: string;
  dateTo: string;
  id: number;
  status: HolidayStatuses;
  reason: string;
  reviewedBy: ShortUserType;
  reviewedAt: string;
  createdAt: string;
  updatedAt: string;
  additionalComment: string | null;
}

export interface TeamHoliday extends Holiday {
  user: ShortUserType;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
interface FetchHolidaysResponseType {
  data: Holiday[];
  error?: ApiError;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
interface FetchTeamHolidaysResponseType {
  data: TeamHoliday[];
  error?: ApiError;
}

export interface FetchHolidaysParams {
  'date'?: `${number}-${number}-${number}`; // date format YYYY-MM-DD
  'view'?: 'week' | 'month';
  'date[from]'?: string;
  'date[to]'?: string;
}

export default class HolidaysService extends ApiBaseService {
  static readonly API_URL = this.API_BASE_URL;

  static fetchUserHolidays = async (params?: FetchHolidaysParams) => {
    try {
      const { data } = await axios.get<FetchHolidaysResponseType>(
        this.API_URL + '/account/holiday_requests',
        { params },
      );
      return data;
    } catch (error) {
      return {
        data: [],
        error: error as ApiError,
      };
    }
  };

  static fetchTeamHolidays = async (params?: FetchHolidaysParams) => {
    try {
      const queryString = params
        ? '?' + new URLSearchParams(params as Record<string, string>).toString()
        : '';
      const { data } = await axios.get<FetchTeamHolidaysResponseType>(
        `${this.API_URL}/holiday_requests${queryString}`,
      );
      return data;
    } catch (error) {
      return {
        data: [],
        error: error as ApiError,
      };
    }
  };
}
